import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import bff, { bffStatus, bffResponse, isCancel } from 'services/bff';
import classes from 'backOffice/viewMovement/viewMovement.module.scss';
import constants from 'services/constants';
import config from 'config';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Routing from 'routing';
import AddressSummary from 'backOfficeComponents/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/animalSummary/animalSummary';
import Button from 'backOfficeComponents/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DateSummary from 'backOfficeComponents/summary/dateSummary/dateSummary';
import ExemptionSummary from 'backOfficeComponents/summary/exemptionSummary/exemptionSummary';
import FciSummary from 'backOfficeComponents/summary/fciSummary/fciSummary';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import TransportSummary from 'backOfficeComponents/summary/transportSummary/transportSummary';
import { holdingHelper } from 'services/holdingHelper';
import { cloneDeep, isEmpty } from 'lodash';

const isNonCPH = (holding) => holding && !holdingHelper.cph.isNonCph(holding);

const determineIfMovementInBusiness = async (setLoadPending, inData, setModal, source, setIsInBusinessMove) => {
  const fromHolding = get.fromHolding(inData);
  const toHolding = get.toHolding(inData);

  if (!holdingHelper.cph.isNonCph(fromHolding) &&
    !holdingHelper.cph.isNonCph(toHolding) &&
    !holdingHelper.cph.isUnknownCph(fromHolding) &&
    !holdingHelper.cph.isUnknownCph(toHolding)
  ) {
    setLoadPending((prevState) => ({
      ...prevState,
      holdingsInBusiness: {
        ...prevState.holdingsInBusiness,
        requestSent: true
      }
    }));

    helpers.get.holdingsInBusiness(source.token, fromHolding)
      .then((holdingRes) => {
        if (helpers.response.isValid(holdingRes?.data?.data, setModal)) {
          setLoadPending((prevState) => ({
            ...prevState,
            holdingsInBusiness: {
              ...prevState.holdingsInBusiness,
              pending: false
            }
          }));

          const holdings = holdingRes.data?.data?.holdings || [];
          // in-business move is when both CPHs is in the same user holdings
          inData.isInBusinessMovement = holdings.some((holding) => holding.cph === toHolding) &&
            holdings.some((holding) => holding.cph === fromHolding);
          setIsInBusinessMove(inData.isInBusinessMovement);
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 404) {
          errors.BFF(error, setModal);
        } else {
          setLoadPending((prevState) => ({
            ...prevState,
            holdingsInBusiness: {
              ...prevState.holdingsInBusiness,
              pending: false
            }
          }));
        }
      });
  }
};

const get = {
  acceptedAndRejectedAnimals: async (setLoadPending, inData, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      reviewHoldingMovements: {
        ...prevState.reviewHoldingMovements,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.reviewHoldingMovements(source.token, inData.requestIdUndone);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        reviewHoldingMovements: {
          ...prevState.reviewHoldingMovements,
          pending: false
        }
      }));

      if (data?.content?.reviewMovement) {
        const acceptedAndRejectedAnimals = data.content.reviewMovement;
        inData.batches = acceptedAndRejectedAnimals?.acceptedBatches;
        inData.batchesRejected = acceptedAndRejectedAnimals?.rejectedBatches;
        inData.devices = acceptedAndRejectedAnimals?.acceptedDevices;
        inData.devicesRejected = acceptedAndRejectedAnimals?.rejectedDevices;
      }
    }
  },

  animalDetails: async (setLoadPending, inData, setModal, setFciData, metFCIWithdrawalPeriods) => {
    const responseData = async (setLoadPending, inData, data, setModal, setFciData, metFCIWithdrawalPeriods) => {
      if (helpers.response.isValid(data, setModal)) {
        setLoadPending((prevState) => ({
          ...prevState,
          request: {
            ...prevState.request,
            pending: false
          },
          requests: {
            ...prevState.request,
            pending: false
          }
        }));

        if (data?.comment) {
          inData.comment = data.comment;
        }
        const batches = helpers.extract.requestBatches(inData, data);
        if (batches.length > 0) {
          inData.batches = batches;
        }
        const devices = helpers.extract.requestDevices(inData, data);
        if (devices.length > 0) {
          inData.devices = devices;
        }
        if (!inData.movementDocument?.fciDetail && data.movementDocument?.fciDetail) {
          inData.movementDocument.fciDetail = data.movementDocument.fciDetail;
        }
        if (!inData.movementDocument?.transportDetail && data.movementDocument?.transportDetail) {
          inData.movementDocument.transportDetail = data.movementDocument.transportDetail;
        }
        if (data.movementDocument?.departureDetail?.expectedDurationOfJourney) {
          inData.movementDocument.departureDetail.expectedDurationOfJourney = data.movementDocument.departureDetail.expectedDurationOfJourney;
        }
        const fciDetail = data?.movementDocument?.fciDetail;
        const recordFCI = fciDetail && typeof fciDetail?.isAllAnimalsFciCompliant === 'boolean' ? constants.option.recordFCI.yes : constants.option.recordFCI.no;
        storeService.session.set.movementFciRecordFci(recordFCI);

        if (helpers.option.fci.doRecord(recordFCI)) {
          // fetch the fciWithdrawalPeriods if it doesn't existed
          const fciWithdrawalPeriods = metFCIWithdrawalPeriods.length > 0
            ? metFCIWithdrawalPeriods
            : await get.fciWithdrawalPeriods(setLoadPending, setModal, axios.CancelToken.source());
          setFciData({
            ...fciDetail,
            recordFCI,
            withdrawalPeriod: helpers.extract.withdrawalPeriod(fciDetail, fciWithdrawalPeriods),
            nonComplianceReason: data.movementDocument?.fciDetail?.nonCompliantReason,
            unfitAnimals: data.movementDocument?.fciDetail?.nonCompliantDevices
          });
        } else {
          setFciData({ ...fciDetail, recordFCI });
        }
      }
    };

    if (!helpers.option.movement.isCrossBorderUnsupportedRequestType(inData.responseRequestType)) {
      setLoadPending((prevState) => ({
        ...prevState,
        request: {
          ...prevState.request,
          requestSent: true
        },
        requests: {
          ...prevState.request,
          requestSent: true
        }
      }));

      const { data } = await helpers.get.request(inData.sourceHolding, inData.requestId, inData.responseRequestType);
      await responseData(setLoadPending, inData, data, setModal, setFciData, metFCIWithdrawalPeriods);
    }
  },

  animalDetailsRejected: async (setLoadPending, reviewRequestId, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      reviewHoldingMovements: {
        ...prevState.reviewHoldingMovements,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.reviewHoldingMovements(source.token, reviewRequestId);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        reviewHoldingMovements: {
          ...prevState.reviewHoldingMovements,
          pending: false
        }
      }));

      const { reviewMovement } = data?.content || {};

      return {
        batchesRejected: reviewMovement.rejectedBatches,
        devicesRejected: reviewMovement.rejectedDevices
      };
    }
    return {
      batchesRejected: null,
      devicesRejected: null
    };
  },

  animalTotal: (inData, totalBatchesAndDevices) => {
    if (inData.totalAnimals) {
      return inData.totalAnimals;
    }
    if (inData.animalCount) {
      return inData.animalCount;
    }
    if (inData.animalTotal) {
      return inData.animalTotal;
    }
    return totalBatchesAndDevices;
  },

  approvedMovement: async (setLoadPending, inData, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      approvedMovement: {
        ...prevState.approvedMovement,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.approvedMovement(source.token, inData);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        approvedMovement: {
          ...prevState.approvedMovement,
          pending: false
        }
      }));

      inData.devices = data.devices;
      inData.batches = data.batches.map((batch) => ({
        animalTotal: batch.animalTotal,
        batchMovementId: batch.batchMovementId,
        batchNumber: batch.batchNumber,
        idMark: batch.idMark,
        stockType: batch.stockType
      }));
      inData.movementDocument = data.movementDocument;
      inData.approvedMovementProcessingFlags = data.approvedMovementProcessingFlags;
    }
  },

  cancelledMovementReference: async (setLoadPending, inData, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      cancelledMovementsList: {
        ...prevState.cancelledMovementsList,
        requestSent: true
      }
    }));

    const toDate = helpers.date.formatYYYYMMDD(new Date());
    const fromDate = helpers.date.formatYYYYMMDD(helpers.date.subtractPeriod(new Date(), 3, constants.period.months));
    const movementRef = inData.movementRef ? inData.movementRef : inData.movementDocument.movementDocumentRef ? inData.movementDocument.movementDocumentRef : inData.movementReferenceUndone;
    const { data } = await helpers.get.cancelledMovementsListFiltered(source.token, inData.fromHolding, inData.toHolding, fromDate, toDate, inData.speciesId, movementRef);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        cancelledMovementsList: {
          ...prevState.cancelledMovementsList,
          pending: false
        }
      }));

      inData.originalMovementRef = data.data[0]?.movementReferenceUndone;
    }
  },

  comment: async (setLoadPending, inData, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      holdingRegistersMovementDetails: {
        ...prevState.holdingRegistersMovementDetails,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.holdingRegistersMovementDetails(source.token, get.movementHolding(inData), inData.requestId);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        holdingRegistersMovementDetails: {
          ...prevState.holdingRegistersMovementDetails,
          pending: false
        }
      }));

      return data?.length > 0 ? data[0].comment : '';
    }
  },

  fciWithdrawalPeriods: async (setLoadPending, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      metFciWithdrawalPeriods: {
        ...prevState.metFciWithdrawalPeriods,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.metFciWithdrawalPeriods(source.token);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        metFciWithdrawalPeriods: {
          ...prevState.metFciWithdrawalPeriods,
          pending: false
        }
      }));

      return data;
    }
    return null;
  },

  fromHolding: (data) => {
    if (holdingHelper.cph.isNonCph(data.userHolding) || holdingHelper.cph.isNonCph(data.fromHolding) || holdingHelper.cph.isNonCph(data.sourceHolding)) {
      return constants.cph.none;
    }
    if (data.fromHolding) {
      return data.fromHolding.trim();
    }
    if (data.sourceHolding) {
      return data.sourceHolding.trim();
    }
    if (data.userHolding) {
      return data.userHolding.trim();
    }
  },

  movementHolding: (movementData) => {
    if (!movementData) {
      return null;
    }
    if (isNonCPH(movementData.sourceHolding)) {
      return movementData.sourceHolding;
    }
    if (isNonCPH(movementData.fromHolding)) {
      return movementData.fromHolding;
    }
    if (isNonCPH(movementData.sourceHolding)) {
      return movementData.sourceHolding;
    }
    return isNonCPH(movementData.toHolding)
      ? movementData.toHolding
      : movementData.destinationHolding;
  },

  movementRef: (location) => {
    if (location?.state?.data) {
      const { data } = location.state;
      if (data.movementRef) {
        return data.movementRef;
      }
      if (data.movementReferenceUndone) {
        return data.movementReferenceUndone;
      }
      return data.movementDocument?.movementDocumentRef ? data.movementDocument.movementDocumentRef : null;
    }
    return null;
  },

  movementSubTitle: (message, movementDocumentRef) => `${movementDocumentRef} ${message ? ' - ' + message : ''}`,

  originalMovementRef: async (setLoadPending, data, setModal, spawnToken) => {
    await get.cancelledMovementReference(setLoadPending, data, setModal, spawnToken());

    if (!data.originalMovementRef) {
      const requestToBeUndoneId = await get.requestToBeUndoneId(setLoadPending, data, setModal, spawnToken());
      const response = await helpers.get.requests(null, requestToBeUndoneId);
      data.originalMovementRef = await get.originalMovementReference(setLoadPending, requestToBeUndoneId, response?.data?.requestTypeId, setModal, spawnToken());
    }
  },

  originalMovementReference: async (setLoadPending, requestToBeUndoneId, responseRequestType, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      requestByIdAndType: {
        ...prevState.requestByIdAndType,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.requestByIdAndType(source.token, requestToBeUndoneId, responseRequestType);

    setLoadPending((prevState) => ({
      ...prevState,
      requestByIdAndType: {
        ...prevState.requestByIdAndType,
        pending: false
      }
    }));

    if (helpers.response.isValid(data, setModal)) {
      return data?.movementDocument?.movementDocumentRef;
    }
    return null;
  },

  rejectedAnimals: async (setLoadPending, data, setModal, spawnToken) => {
    const reviewStatus = await get.reviewStatus(setLoadPending, data.movementRef, setModal, spawnToken());

    let rejectedAnimals;

    if (reviewStatus.departureReviewStatus?.reviewStatusStatus === 'ReviewedAndRejected') {
      rejectedAnimals = await get.animalDetailsRejected(setLoadPending, reviewStatus.departureReviewStatus.reviewRequestId, setModal, spawnToken());
      data.reviewStatus = reviewStatus.departureReviewStatus?.reviewStatusStatus;
      data.reviewRequestId = reviewStatus.departureReviewStatus?.reviewRequestId;
    } else if (reviewStatus.arrivalReviewStatus?.reviewStatusStatus === 'ReviewedAndRejected') {
      rejectedAnimals = await get.animalDetailsRejected(setLoadPending, reviewStatus.arrivalReviewStatus.reviewRequestId, setModal, spawnToken());
      data.reviewStatus = reviewStatus.arrivalReviewStatus?.reviewStatusStatus;
      data.reviewRequestId = reviewStatus.arrivalReviewStatus?.reviewRequestId;
    }

    if (rejectedAnimals) {
      data.batchesRejected = rejectedAnimals.batchesRejected;
      data.devicesRejected = rejectedAnimals.devicesRejected;
      data.animalTotal = 0;
    }
  },

  requestByIdAndType: async (setLoadPending, inData, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      requestByIdAndType: {
        ...prevState.requestByIdAndType,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.requestByIdAndType(source.token, inData.requestId, inData.responseRequestType);

    setLoadPending((prevState) => ({
      ...prevState,
      requestByIdAndType: {
        ...prevState.requestByIdAndType,
        pending: false
      }
    }));

    if (data) {
      storeService.session.set.request(data);
    }
  },

  requestToBeUndoneId: async (setLoadPending, inData, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      requestByIdAndType: {
        ...prevState.requestByIdAndType,
        requestSent: true
      }
    }));

    const { data } = await helpers.get.requestByIdAndType(source.token, inData.requestId, inData.responseRequestType);

    if (helpers.response.isValid(data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        requestByIdAndType: {
          ...prevState.requestByIdAndType,
          pending: false
        }
      }));

      return data?.requestToBeUndoneId;
    }
  },

  reviewStatus: async (setLoadPending, movementRef, setModal, source) => {
    setLoadPending((prevState) => ({
      ...prevState,
      reviewStatus: {
        ...prevState.reviewStatus,
        requestSent: true
      }
    }));

    const statuses = await helpers.get.reviewStatus(source.token, movementRef);

    if (helpers.response.isValid(statuses.data, setModal)) {
      setLoadPending((prevState) => ({
        ...prevState,
        reviewStatus: {
          ...prevState.reviewStatus,
          pending: false
        }
      }));

      return statuses.data;
    }
  },

  totalBatchAnimals: (inData) => {
    if (inData?.batches?.length > 0) {
      return inData.batches.reduce((total, batch) => total + batch.animalTotal, 0);
    }
    if (inData?.movementGroups?.[0]?.batches?.length > 0) {
      return inData.movementGroups[0].batches.reduce((total, batch) => total + batch.animalTotal, 0);
    }
    return 0;
  },

  totalBatchAnimalsRejected: (inData) => {
    if (inData?.batchesRejected?.length > 0) {
      return inData.batchesRejected.reduce((total, batch) => total + batch.animalTotal, 0);
    }
    if (inData?.movementGroups?.[0]?.batchesRejected?.length > 0) {
      return inData.movementGroups[0].batchesRejected.reduce((total, batch) => total + batch.animalTotal, 0);
    }
    return 0;
  },

  totalIndividualAnimals: (inData) => {
    if (inData?.devices?.length > 0) {
      return inData.devices.length;
    }
    if (inData?.movementGroups?.[0]?.devices?.length > 0) {
      return inData.movementGroups[0].devices.length;
    }
    return 0;
  },

  totalIndividualAnimalsRejected: (inData) => {
    if (inData?.devicesRejected?.length > 0) {
      return inData.devicesRejected.length;
    }
    if (inData?.movementGroups?.[0]?.devicesRejected?.length > 0) {
      return inData.movementGroups[0].devicesRejected.length;
    }
    return 0;
  },

  toHolding: (data) => {
    if (holdingHelper.cph.isNonCph(data.toHolding) || holdingHelper.cph.isNonCph(data.destinationHolding)) {
      return constants.cph.none;
    }
    if (data.destinationHolding) {
      return data.destinationHolding.trim();
    }
    if (data.toHolding) {
      return data.toHolding.trim();
    }
  }
};

const initialProcessData = (data) => {
  // For Common land CPHs, replace id with movementId #66965
  if (data.movementId) {
    data.id = data.movementId;
  }
  data.fromHolding = get.fromHolding(data);
  data.toHolding = get.toHolding(data);
  data.bothEnglishHoldings =
    (
      holdingHelper.cph.isEnglish(data.fromHolding) ||
      holdingHelper.cph.isNonCph(data.fromHolding)
    ) &&
    (
      holdingHelper.cph.isEnglish(data.toHolding) ||
      holdingHelper.cph.isNonCph(data.toHolding)
    );
  data.speciesId = data.species ? helpers.species.nameToId(data?.species) : data?.speciesId;
};

const initRequest = async (setLoadPending, data, setModal, source) => {
  setLoadPending((prevState) => ({
    ...prevState,
    requests: {
      ...prevState.requests,
      pending: false
    }
  }));

  const res = await helpers.get.requests(source.token, data.requestId);

  if (helpers.response.isValid(res.data, setModal)) {
    setLoadPending((prevState) => ({
      ...prevState,
      requests: {
        ...prevState.requests,
        pending: false
      }
    }));

    return res.data;
  }
  return null;
};

const BoViewMovement = ({
  location,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState(location?.state?.data ? location?.state?.data : {});
  const [doc, setDoc] = React.useState(data?.movementDocument ?? null);

  const [batches, setBatches] = React.useState([]);
  const [batchesRejected, setBatchesRejected] = React.useState([]);
  const [individualAnimals, setIndividualAnimals] = React.useState([]);
  const [individualAnimalsRejected, setIndividualAnimalsRejected] = React.useState([]);
  const [totalBatchAnimals, setTotalBatchAnimals] = React.useState(0);
  const [totalIndividualAnimals, setTotalIndividualAnimals] = React.useState(0);
  const [totalAnimals, setTotalAnimals] = React.useState(0);
  const [totalBatchAnimalsRejected, setTotalBatchAnimalsRejected] = React.useState(0);
  const [totalIndividualAnimalsRejected, setTotalIndividualAnimalsRejected] = React.useState(0);
  const [totalAnimalsRejected, setTotalAnimalsRejected] = React.useState(0);

  const [transportInformation, setTransportInformation] = React.useState({ recordTransportInformation: constants.option.recordTransportInformation.no });
  const [fciData, setFciData] = React.useState({});

  const [processingFlags, setProcessingFlags] = React.useState({ exempt: '', exemptions: [] });
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState([]);
  const [showAmendButton, setShowAmendButton] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(null);

  const [confirmMode, setConfirmMode] = React.useState(false);
  const [arrivalDate, setArrivalDate] = React.useState(null);
  const [arrivalDateError, setArrivalDateError] = React.useState(null);

  const [action, setAction] = React.useState(null);
  const [movementRef, setMovementRef] = React.useState(get.movementRef(location));
  const [movementReferences, setMovementReferences] = React.useState([]);

  const [pageErrors, setPageErrors] = React.useState([]);
  const [loadPending, setLoadPending] = React.useState({
    approvedMovement: {
      requestSent: false,
      pending: true
    },
    cancelledMovementsList: {
      requestSent: false,
      pending: true
    },
    holdingsInBusiness: {
      requestSent: false,
      pending: true
    },
    holdingRegistersMovementDetails: {
      requestSent: false,
      pending: true
    },
    movementAnimals: {
      requestSent: false,
      pending: true
    },
    request: {
      requestSent: false,
      pending: true
    },
    requests: {
      requestSent: false,
      pending: true
    },
    requestByIdAndType: {
      requestSent: false,
      pending: true
    },
    reviewHoldingMovements: {
      requestSent: false,
      pending: true
    },
    reviewStatus: {
      requestSent: false,
      pending: true
    }
  });
  const [submitPending, setSubmitPending] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [pollingStatus, setPollingStatus] = React.useState(null);
  const [viewErrors, setViewErrors] = React.useState([]);
  const [viewWarnings, setViewWarnings] = React.useState([]);
  const [viewStatus, setViewStatus] = React.useState('');
  const [speciesId, setSpeciesId] = React.useState(null);
  const [requestId, setRequestId] = React.useState(null);
  const [requestType, setRequestType] = useState(null);
  const [isPermitMovement, setIsPermitMovement] = useState(false);
  const [isInBusinessMovement, setIsInBusinessMovement] = useState(false);

  const processCommonData = (inValue) => {
    storeService.session.set.movement(inValue);

    const { fciDetail } = inValue.movementDocument;
    const isAllAnimalsFciCompliant = typeof inValue.movementDocument.fciDetail?.isAllAnimalsFCICompliant === 'boolean';
    const recordFCI = fciDetail && isAllAnimalsFciCompliant ? constants.option.recordFCI.yes : constants.option.recordFCI.no;
    storeService.session.set.movementFciRecordFci(recordFCI);

    if (helpers.option.fci.doRecord(recordFCI)) {
      storeService.session.set.movementFciWithdrawalPeriod(helpers.extract.withdrawalPeriod(fciDetail, metFCIWithdrawalPeriods));
      storeService.session.set.movementFciAnimalsSatisfy(helpers.extract.animalsSatisfy(fciDetail));

      if (fciDetail.nonCompliantDevices?.length > 0) {
        storeService.session.set.movementFciUnfitAnimals(fciDetail.nonCompliantDevices.map((animal) => animal.tagNumber));
      }
      if (fciDetail.holdingRestrictions) {
        storeService.session.set.movementFciHoldingRestrictions(fciDetail.holdingRestrictions);
      }
      if (fciDetail.nonCompliantReason) {
        storeService.session.set.movementFciNonComplianceReason(fciDetail.nonCompliantReason);
      }
    }
  };

  const processExemptions = (processingFlags, responseData) => {
    const exemptions = processingFlags.map((o) => ({
      id: o.processingFlagId,
      label: helpers.extract.processingLabel(responseData, o.processingFlagId),
      name: helpers.extract.processingName(responseData, o.processingFlagId),
      value: true
    }));
    const all = responseData.map((o) => ({
      id: o.id,
      label: o.label,
      value: helpers.extract.processingStatus(processingFlags, o.id)
    }));
    const savedProcessingFlags = {
      exempt: 'yes',
      exemptions,
      all
    };
    setProcessingFlags(savedProcessingFlags);
    storeService.session.set.movementExemptions(exemptions);
  };

  const retrieveMovementReferences = (inData) => {
    return [
      {
        value: inData?.movementDocument?.movementDocumentRef,
        label: 'label.movementRef'
      },
      {
        value: !inData?.movementDocument?.movementDocumentRef ? inData?.movementReferenceUndone : undefined,
        label: 'label.movementRef'
      },
      {
        value: inData?.movementReferenceCorrected,
        label: 'label.movementRefNew'
      },
      {
        value: !inData?.movementReferenceCorrected ? inData?.originalMovementRef : undefined,
        label: 'label.originalMovementReference'
      },
      {
        value: !(inData?.movementReferenceCorrected || inData?.originalMovementRef) ? inData?.movementRefCorrected : undefined,
        label: 'label.originalMovementReference'
      }
    ];
  };

  const processData = (inData) => {
    setMovementReferences(retrieveMovementReferences(inData));
    processCommonData(inData);

    // eslint-disable-next-line no-unused-vars
    const { batches, devices, ...restInData } = inData;
    setData((prevState) => ({
      ...prevState,
      ...restInData
    }));

    setDoc(inData.movementDocument);

    if (inData.movementDocument?.destinationDetail?.arrivalDateTime) {
      // uncommented below will pass the arrival date string to be edited
      setArrivalDate(inData.movementDocument.destinationDetail.arrivalDateTime);
    }
    setBatches(inData.batches ?? []);
    setBatchesRejected(inData.batchesRejected ?? []);
    setIndividualAnimals(inData.devices ?? []);
    setIndividualAnimalsRejected(inData.devicesRejected ?? []);

    const devicesTotal = get.totalIndividualAnimals(inData);
    setTotalIndividualAnimals(devicesTotal);

    const rejectedDevicesTotal = get.totalIndividualAnimalsRejected(inData);
    setTotalIndividualAnimalsRejected(rejectedDevicesTotal);

    const batchesTotal = get.totalBatchAnimals(inData);
    setTotalBatchAnimals(batchesTotal);

    const rejectedBatchesTotal = get.totalBatchAnimalsRejected(inData);
    setTotalBatchAnimalsRejected(rejectedBatchesTotal);

    const animalTotal = get.animalTotal(inData, batchesTotal + devicesTotal);
    setTotalAnimals(animalTotal);
    setTotalAnimalsRejected(rejectedBatchesTotal + rejectedDevicesTotal);

    setTInfo(inData.movementDocument);

    const processingFlags = helpers.extract.processingFlags(inData);
    if (processingFlags?.length > 0) {
      const source = axios.CancelToken.source();
      helpers.get.processingFlags(source.token, constants.option.requestType.movement, inData.speciesId)
        .then(({ data }) => {
          if (helpers.response.isValid(data, setModal)) {
            processExemptions(processingFlags, data);
          }
        });
    } else {
      storeService.session.set.movementExemptions([]);
      setProcessingFlags({ exempt: 'yes', exemptions: [] });
    }

    setSubmitPending(false);
  };

  const onClick = {
    amendMovement: () => {
      history.push(Routing.boEditMovements);
    },

    confirmMovement: () => {
      helpers.scrollToTop(document.getElementById('appContent'));
      setConfirmMode(true);
    },

    confirmMovementCancel: () => {
      setConfirmMode(false);
      setArrivalDate(null);
    },

    goBackSearchPage: () => {
      history.push(Routing.boSearchMovements);
    },

    searchByMovementReference: () => {
      helpers.redirect.searchByMovementReference(history, movementRef);
    },

    undoConfirmation: () => {
      setShowAmendButton(true);
      history.push(Routing.boSearchMovements);
    }
  };

  const getCph = (recordedByDestination, data) => {
    let cph = recordedByDestination ? (data.sourceHolding || data.fromHolding) : (data.destinationHolding || data.toHolding);
    if (holdingHelper.cph.isWelsh(cph) || holdingHelper.cph.isScottish(cph)) {
      cph = recordedByDestination ? (data.destinationHolding || data.toHolding) : (data.sourceHolding || data.fromHolding);
    }
    return cph;
  };

  const handleSubmission = {
    createConfirmQuery: () => {
      const recordedByDestination = helpers.option.recordBy.isByDestination(data.recordedBy);
      const isMoveToCommonLand = holdingHelper.cph.isCommonLand(data.toHolding);
      const tempData = cloneDeep(data);

      tempData.departureDateTime = tempData.movementDocument.departureDetail.departureDateTime;

      tempData.reviewBatches = helpers.extract.batches(tempData);
      tempData.reviewDevices = helpers.extract.devices(tempData);
      const totalAnimals = tempData.reviewBatches.reduce((total, batch) => total + batch.animalTotal, 0) + tempData.reviewDevices.length;

      tempData.removedBatches = [];
      tempData.removedDevices = [];
      tempData.transferDate = tempData.transferDate ? tempData.transferDate : tempData.movementDate;
      tempData.destinationHolding = tempData.toHolding;

      delete tempData.movementDate;

      const outData = {
        poll: config.POLLS_ENABLED,
        requestId: data.requestId,
        isAccepted: true,
        data: {
          ...tempData,
          arrivalDate,
          cph: getCph(recordedByDestination, data)
        }
      };

      delete outData.data.arrivalDateTime;

      if (recordedByDestination) {
        outData.data.totalAnimals = totalAnimals;
        delete outData.data.arrivalDate;
      } else if (isMoveToCommonLand) {
        outData.data.totalAnimals = totalAnimals;
      }

      delete outData.data.batches;
      delete outData.data.devices;
      return outData;
    },

    createRejectQuery: () => {
      const recordedByDestination = helpers.option.recordBy.isByDestination(data.recordedBy);
      const tempData = cloneDeep(data);

      const batches = helpers.extract.batches(tempData);
      tempData.removedBatches = batches?.length > 0 ? batches.map((batch) => ({
        animalTotal: batch.animalTotal,
        batchNumber: batch.batchNumber,
        batchMovementId: batch.batchMovementId
      })) : [];
      const devices = helpers.extract.devices(tempData);
      tempData.removedDevices = devices?.length > 0 ? devices.map((device) => ({
        tagNumber: device.tagNumber
      })) : [];
      tempData.reviewBatches = [];
      tempData.reviewDevices = [];

      let arrivalDateTime = helpers.date.appendTime(arrivalDate, moment(data.transferDate).hours(), moment(data.transferDate).minutes(), moment(data.transferDate).seconds());
      if (helpers.date.is.inTheFuture(arrivalDateTime)) {
        arrivalDateTime = helpers.date.appendTime(arrivalDate, moment().hours(), moment().minutes(), moment().seconds());
      }

      const outData = {
        poll: config.POLLS_ENABLED,
        requestId: data.requestId,
        isAccepted: false,
        data: {
          ...tempData,
          arrivalDate: arrivalDateTime,
          cph: getCph(recordedByDestination, data)
        }
      };

      delete outData.data.arrivalDateTime;

      if (recordedByDestination) {
        delete outData.data.arrivalDate;
      }

      delete outData.data.batches;
      delete outData.data.devices;
      return outData;
    },

    postQuery: (params, postType, apiCall) => {
      setAction(postType);
      setSubmitted(true);
      setSubmitPending(true);
      setPageErrors([]);

      bff
        .post(apiCall, params)
        .then(({ data }) => {
          if (data.errorCode && data.errorCode === 400) {
            setPollingStatus(constants.status.error);
          } else {
            handleSubmission.response(data);
          }
          storeService.session.removeAll.searchResults();
        })
        .catch((error) => {
          setPageErrors([{
            code: error?.response?.status,
            message: error?.response?.data?.errorMessage
          }]);
        })
        .finally(() => {
          setSubmitPending(false);
        });
    },

    response: (data) => {
      setPageErrors(bffResponse.errors(data));
      setPollingStatus(bffResponse.status(data));
      setMovementRef(bffResponse.movementRef(data));
      setRequestId(bffResponse.requestId(data));
    },

    submitConfirm: () => {
      handleSubmission.postQuery(
        handleSubmission.createConfirmQuery(),
        constants.postType.CONFIRM,
        '/handshake'
      );
    },

    submitReject: () => {
      handleSubmission.postQuery(
        handleSubmission.createRejectQuery(),
        constants.postType.REJECT,
        '/handshake'
      );
    },

    submitUndoDelete: () => {
      setAction(constants.postType.DELETE);
      setSubmitted(true);
      setSubmitPending(true);
      setShowConfirmModal(null);

      bff
        .post('/undoRequest', {
          poll: config.POLLS_ENABLED,
          requestId: data.requestId
        })
        .then(({ data }) => {
          handleSubmission.response(data);
          storeService.session.removeAll.searchResults();
        })
        .catch((error) => {
          errors.BFF(error, setModal);
        })
        .finally(() => {
          setSubmitPending(false);
        });
    },

    submitUndo: () => {
      setAction(constants.postType.UNDO);
      setSubmitted(true);
      setSubmitPending(true);
      setShowConfirmModal(null);

      bff
        .post('/undoRequest', {
          poll: config.POLLS_ENABLED,
          requestId: data.requestId
        })
        .then(({ data }) => {
          handleSubmission.response(data);
          storeService.session.removeAll.searchResults();
        })
        .catch((error) => {
          errors.BFF(error, setModal);
        })
        .finally(() => {
          setSubmitPending(false);
        });
    },

    submitUndoReview: () => {
      setAction(constants.postType.UNDO);
      setSubmitted(true);
      setSubmitPending(true);
      setShowConfirmModal(null);

      bff
        .post('/undoRequest', {
          poll: config.POLLS_ENABLED,
          requestId: data.reviewRequestId
        })
        .then(({ data }) => {
          handleSubmission.response(data);
          storeService.session.removeAll.searchResults();
        })
        .catch((error) => {
          errors.BFF(error, setModal);
        })
        .finally(() => {
          setSubmitPending(false);
        });
    }
  };

  const setTInfo = (inValue) => {
    if (inValue && inValue.transportDetail) {
      const tInfo = inValue.transportDetail;
      if (
        (tInfo.transportAuthNumber?.length) ||
        (tInfo.transportVehicleRegistrationNo?.length) ||
        tInfo.transporterType) {

        const transportedBy = helpers.lookup.transportedBy(tInfo.transporterTypeId);
        setTransportInformation({
          arrivalDateTime: (inValue.destinationDetail) ? inValue.destinationDetail.arrivalDateTime : '',
          contactPhone: (tInfo.transporter) ? tInfo.transporter.telephoneNumber : '',
          departureDateTime: (inValue.departureDetail) ? inValue.departureDetail.departureDateTime : '',
          durationOfJourney: (inValue.departureDetail) ? inValue.departureDetail.expectedDurationOfJourney : '',
          recordTransportInformation: constants.option.recordTransportInformation.yes,
          transportAuthNumber: tInfo.transporterAuthNumber,
          transportedBy,
          transporterTypeId: tInfo.transporterTypeId,
          transporterType: tInfo.transporterType,
          transporter: tInfo.transporter,
          vehicleRegistrationNumber: tInfo.transportVehicleRegistrationNo
        });
      }
    }
  };

  const determineButtonsToShow = () => {
    if (location?.state?.data) {
      const approved = location.state.data.approved ? location.state.data.approved : false;
      setShowAmendButton(!approved);
    }
  };

  const undoConfirmation = () => {
    return (
      <Confirmation
        buttons={[
          {
            id: 'return',
            label: 'button.return',
            onClick: onClick.goBackSearchPage
          }
        ]}
        confirm={{
          content: pageErrors?.length > 0 ? 'label.submissionErrorContent' : null,
          id: data.movementRef,
          label: 'label.movementRef'
        }}
        errors={pageErrors}
        label="boApp.label.movementReviewUndone"
        setModal={setModal}
        status={pollingStatus}
      />
    );
  };

  const deleteConfirmation = () => {
    let label = null;
    if (bffStatus.isPending(pollingStatus) || bffStatus.isClaPending(pollingStatus)) {
      label = 'boApp.label.movementDeletionPending';
    } else if (bffStatus.isCompletedError(pollingStatus)) {
      label = 'boApp.label.movementDeletionWithError';
    } else {
      label = 'boApp.label.movementDeleted';
    }

    return (
      <Confirmation
        buttons={[
          {
            id: 'return',
            label: 'button.return',
            onClick: onClick.goBackSearchPage
          }
        ]}
        confirm={{
          content: pageErrors && pageErrors?.length > 0 ? 'label.submissionErrorContent' : null,
          id: String(requestId),
          label: 'label.requestId'
        }}
        errors={pageErrors}
        label={label}
        setModal={setModal}
        status={pollingStatus}
      />
    );
  };

  const confirmedConfirmation = () => {
    return (
      <Confirmation
        buttons={[
          {
            id: 'return',
            label: 'button.return',
            onClick: onClick.goBackSearchPage
          }
        ]}
        confirm={{
          id: movementRef,
          label: 'label.movementRefWithLink',
          onClick: onClick.searchByMovementReference
        }}
        label="boApp.label.movementConfirmed"
        setModal={setModal}
        status={pollingStatus}
      />
    );
  };

  const rejectedConfirmation = () => {
    let label = null;
    if (bffStatus.isPending(pollingStatus) || bffStatus.isClaPending(pollingStatus)) {
      label = 'boApp.label.movementRejectPending';
    } else if (bffStatus.isCompletedError(pollingStatus)) {
      label = 'boApp.label.movementRejectWithError';
    } else {
      label = 'boApp.label.movementRejected';
    }

    return (
      <Confirmation
        buttons={[
          {
            id: 'recordAnother',
            label: 'button.recordAnotherMovement',
            onClick: onClick.undoConfirmation
          }
        ]}
        confirm={{
          content: pageErrors && pageErrors?.length > 0 ? 'label.submissionErrorContent' : null,
          id: String(requestId),
          label: 'label.requestId'
        }}
        errors={pageErrors}
        label={label}
        setModal={setModal}
        status={pollingStatus}
      />
    );
  };

  React.useEffect(() => {
    const sources = [];
    const spawnToken = () => {
      sources.push(axios.CancelToken.source());
      return sources[sources.length - 1];
    };
    const saveMovementRequestToSession = async (data) => {
      if (helpers.option.requestType.isMovement(data.responseRequestType) || helpers.option.requestType.isCorrectedMovement(data.responseRequestType)) {
        await get.requestByIdAndType(setLoadPending, data, spawnToken());
      }
    };

    const initData = location.state?.data;
    const dataProcessingAsync = async () => {
      if (initData) {
        try {
          const initPromises = [];
          initPromises.push(get.fciWithdrawalPeriods(setLoadPending, setModal, spawnToken()));
          initPromises.push(initRequest(setLoadPending, data, setModal, spawnToken()));
          initPromises.push(determineIfMovementInBusiness(setLoadPending, data, setModal, spawnToken(), setIsInBusinessMovement));

          const [fciWithdrawalPeriods, initRequestData] = await Promise.all([...initPromises]);
          data.responseRequestType = initRequestData.requestTypeId;

          initialProcessData(data);
          if (fciWithdrawalPeriods) {
            setMetFCIWithdrawalPeriods(fciWithdrawalPeriods);
          }
          setRequestType(data.responseRequestType);
          storeService.session.remove.request();
          saveMovementRequestToSession(data).catch((error) => {
            console.error('Error during saveMovementRequestToSession:', error);
          });
          setSpeciesId(data.speciesId);

          await get.animalDetails(setLoadPending, data, setModal, setFciData, metFCIWithdrawalPeriods);
          if (!data.confirmed) {
            await get.rejectedAnimals(setLoadPending, data, setModal, spawnToken);
          }

          // use approved move API if both CPHs are English holding or request TYpe is 229
          if ((data.bothEnglishHoldings || helpers.option.movement.isCrossBorderUnsupportedRequestType(data.responseRequestType)) && (!data.cancelledMove)) {
            await get.approvedMovement(setLoadPending, data, setModal, spawnToken());

            if (holdingHelper.cph.isNonCph(data.fromHolding) || holdingHelper.cph.isNonCph(data.toHolding)) {
              setIsPermitMovement(true);
              if (!data.comment) {
                data.comment = await get.comment(setLoadPending, data, setModal, spawnToken());
              }
            } else if (helpers.option.requestType.isCorrectedMovement(data.responseRequestType) && helpers.messageStatus.isUnconfirmed(movementStatus)) {
              await get.originalMovementRef(setLoadPending, data, setModal, spawnToken);
            }
          } else if (data.cancelledMove && (helpers.undoRequest.isReview(data.requestTypeUndone))) {
            const cancelledReviewPromises = [];
            cancelledReviewPromises.push(get.cancelledMovementReference(setLoadPending, data, setModal, spawnToken()));
            cancelledReviewPromises.push(get.acceptedAndRejectedAnimals(setLoadPending, data, setModal, spawnToken()));
            await Promise.all([...cancelledReviewPromises]);
          }
          // 229 for paper movement with cross border movement should use approvement
          processData(data);
          setViewErrors(bffResponse.errors(initRequestData));
          setViewWarnings(bffResponse.warnings(initRequestData));
          setViewStatus(initRequestData.requestStatus && !bffStatus.isSuccess(initRequestData.requestStatus) ? initRequestData.requestStatus : null);
        } catch (error) {
          errors.BFF(error, setModal);
        }
      }
      determineButtonsToShow();
    };
    dataProcessingAsync().catch((error) => {
      console.error('Error during dataProcessingAsync:', error);
    });
    return () => {
      sources.forEach((source) => source.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (doc && doc.fciDetail) {
      const isAllAnimalsFciCompliant = typeof doc.fciDetail?.isAllAnimalsFciCompliant === 'boolean' || typeof doc.fciDetail?.isAllAnimalsFCICompliant === 'boolean';
      const recordFCI = doc.fciDetail && isAllAnimalsFciCompliant ? constants.option.recordFCI.yes : constants.option.recordFCI.no;
      setFciData({
        recordFCI,
        animalsSatisfy: recordFCI === constants.option.recordFCI.yes ? helpers.extract.animalsSatisfy(doc.fciDetail) : null,
        holdingRestrictions: doc.fciDetail.holdingRestrictions,
        id: doc.fciDetail?.id, // Not currently used
        nonComplianceReason: doc.fciDetail.nonCompliantReason,
        withdrawalPeriod: helpers.extract.withdrawalPeriod(doc.fciDetail, metFCIWithdrawalPeriods),
        unfitAnimals: doc.fciDetail.nonCompliantDevices
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, metFCIWithdrawalPeriods]);

  React.useEffect(() => {
    helpers.scrollToTop(document.getElementById('appContent'));
  }, []);

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    if (location.state?.data && location.state.data.isPreMovement !== undefined && location.state.data.movementPendingId !== undefined && location.state.data.movementId !== undefined) {
      setLoadPending((prevState) => ({
        ...prevState,
        movementAnimals: {
          ...prevState.movementAnimals,
          requestSent: true
        }
      }));

      bff
        .get('/movementAnimals', {
          cancelToken: source.token,
          params: {
            isPreMovement: location.state.data.isPreMovement,
            movementPendingId: location.state.data.movementPendingId,
            movementId: location.state.data.movementId
          }
        })
        .then((res) => {
          setData((prevState) => ({
            ...prevState,
            devices: res.data.data.devices.map((device) => ({
              tagNumber: device.tagNumber
            })),
            batches: res.data.data.batches.map((batch) => ({
              animalTotal: batch.animalTotal,
              batchNumber: batch.batchNumber,
              batchMovementId: batch.batchMovementId
            }))
          }));
          setLoadPending((prevState) => ({
            ...prevState,
            movementAnimals: {
              ...prevState.movementAnimals,
              pending: false
            }
          }));
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayErrorOrWarnings = (title, listOfStatus, bottomBorder) => {
    const newList = helpers.error.consolidate(listOfStatus);
    return (ready &&
      <div className={classes.status + bottomBorder ? ' ' + classes.noBottomBorder : ''}>
        <div className={classes.title}>
          {title}
        </div>
        {newList.map((status, index) => (
          <div className={classes.code} key={index}>
            {status.code + ' - ' + helpers.error.mapping(status.message)}
          </div>
        ))}
      </div>
    );
  };

  const buttons = {
    confirmButtonWithChecked: () => (
      <Button
        buttonType="secondary"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending) || !arrivalDate || arrivalDateError !== ''}
        id="submitConfirmMovementButton"
        label="button.submitConfirmation"
        onClick={handleSubmission.submitConfirm}
      />
    ),

    confirmButton: () => (
      <Button
        buttonType="primary"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="confirmMovementButton"
        label="button.confirmMovement"
        onClick={onClick.confirmMovement}
      />
    ),

    undoButton: () => (
      <Button
        buttonType="danger"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="undoReviewButton"
        label="button.undoReview"
        onClick={() => setShowConfirmModal({
          action: handleSubmission.submitUndo,
          label: 'boApp.label.confirmUndoReview'
        })}
      />
    ),

    undoReviewButton: () => (
      <Button
        buttonType="danger"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="undoReviewButton"
        label="button.undoReview"
        onClick={() => setShowConfirmModal({
          action: handleSubmission.submitUndoReview,
          label: 'boApp.label.confirmUndoReview'
        })}
      />
    ),

    amendButton: () => (
      <Button
        buttonType="primary"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="amendMovementButton"
        label="button.amendMovement"
        onClick={onClick.amendMovement}
      />
    ),

    deleteButton: () => (
      <Button
        buttonType="danger"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="deleteButton"
        label="button.deleteMovement"
        name="deleteButton"
        onClick={() => setShowConfirmModal({
          action: handleSubmission.submitUndoDelete,
          label: data.confirmed ? 'boApp.label.confirmDeleteMovement' : 'boApp.label.confirmDeleteUnconfirmedMovement'
        })}
      />
    ),

    cancelButton: () => (
      <Button
        buttonType="tertiary"
        id="cancelButton"
        label="button.cancel"
        onClick={onClick.confirmMovementCancel}
      />
    ),

    backSearchButton: () => (
      <Button
        buttonType="tertiary"
        id="returnButton"
        label="button.return"
        onClick={onClick.goBackSearchPage}
      />
    ),

    rejectButton: () => (
      <Button
        buttonType="danger"
        disabled={Object.values(loadPending).some((item) => item.requestSent && item.pending)}
        id="rejectReviewButton"
        label="button.rejectMovementReview"
        name="rejectReviewButton"
        onClick={() => setShowConfirmModal({
          action: handleSubmission.submitReject,
          label: 'boApp.label.confirmRejectMovementReview'
        })}
      />
    )
  };

  const isMovementHandshake = helpers.option.requestType.isMovementHandshake(requestType);
  const crossBorderMove = data.crossBorderMove !== null && data.crossBorderMove !== undefined ? data.crossBorderMove : false;
  const moreThan1Record = data.confirmed ?? false;
  const isCancelledMove = data.cancelledMove ?? false;
  const isReviewedAndRejected = data.reviewStatus === 'ReviewedAndRejected';
  const isXBReportMove = data.userHolding && holdingHelper.cph.isNotEnglish(data.userHolding);
  const isCPHUnknown = holdingHelper.cph.isUnknownCph(data.fromHolding) || holdingHelper.cph.isUnknownCph(data.toHolding);
  // As crossBorderMove in line 1516 is not returning Value hence implementated below condition for US 93422
  const isCrossBorderMove = location?.state?.data?.movementStatus === 'XB';
  const isReportEnglish = data.userHolding !== null && data.userHolding !== undefined ? (data.userHolding && holdingHelper.cph.isEnglish(data.userHolding))
    : data.sourceHolding !== null && data.sourceHolding !== undefined ? (data.sourceHolding && holdingHelper.cph.isEnglish(data.sourceHolding)) : false;

  let movementStatus = location?.state?.data?.movementStatus ? location.state.data.movementStatus : constants.option.messageStatus.default;
  // unconfirmed movement and correct movement type (i.e. amended movement)
  if (helpers.option.requestType.isCorrectedMovement(requestType) && helpers.messageStatus.isUnconfirmed(movementStatus)) {
    movementStatus = constants.option.messageStatus.amended;
  } else if (isReviewedAndRejected) {
    movementStatus = constants.option.messageStatus.rejectedMovementReview;
  }

  return (
    <>
      {ready &&
        <>
          {(!submitted ||
            (
              submitted &&
              !submitPending &&
              pageErrors?.length > 0
            )
          ) &&
            <>
              {submitted && !submitPending && pageErrors?.length > 0 && // Submission error
                <Confirmation
                  errors={pageErrors}
                  label="label.submissionError"
                  setModal={setModal}
                  status={constants.status.error}
                />
              }

              {showConfirmModal &&
                <ModalConfirm
                  id="modalConfirmAction"
                  modalClose={() => setShowConfirmModal(null)}
                  modalConfirm={showConfirmModal.action}
                  modalMessage={showConfirmModal.label}
                  modalTitle="button.confirm"
                />
              }
              {movementRef && requestType &&
                <PageTitle
                  id="viewMovementTitle"
                  pageTitleOne="boApp.pageTitles.movements.movements"
                  pageTitleTwo={get.movementSubTitle(t('title.viewMovement.' + movementStatus), movementRef)}
                  speciesId={speciesId}
                />
              }
              {!isEmpty(data) &&
                <div className="pageContent">
                  <SectionTitleBar
                    icon="bi bi-arrows-move"
                    id="movementDetailsSectionTitle"
                    status={isCancelledMove || viewStatus ? {
                      className: viewStatus,
                      label: 'boApp.sectionTitles.status.' + ((isCancelledMove && !viewStatus) || (isCancelledMove && viewStatus) ? 'Cancelled' : viewStatus),
                      hint: isCancelledMove && !viewStatus ? null : 'boApp.sectionTitles.statusHelp.' + viewStatus
                    } : null}
                    title="boApp.sectionTitles.movementDetails"
                  />

                  {movementReferences?.map((movementReference, key) => (
                    movementReference.value &&
                    <div className={classes.summaryRow} key={key}>
                      <div className={classes.label}>{t(movementReference.label)}</div>
                      <div className={classes.value}>{movementReference.value}</div>
                    </div>
                  ))
                  }

                  {(arrivalDate || (data.movementDocument?.departureDetail?.departureDateTime)) &&
                    <DateSummary
                      arrivalDate={arrivalDate ? arrivalDate : data.movementDocument.destinationDetail.arrivalDateTime}
                      arrivalDateError={arrivalDateError}
                      confirmMode={confirmMode}
                      departureDate={data.movementDocument.departureDetail.departureDateTime}
                      label="label.movementDates"
                      setArrivalDate={setArrivalDate}
                      setArrivalDateError={setArrivalDateError}
                    />
                  }

                  {isPermitMovement
                    ? <AddressSummary
                      info1={get.movementHolding(data)}
                      info2="movements.permitMove.title"
                      info3={data.comment}
                      isPermitMove={isPermitMovement}
                      label="movementDetails.searchResults.movementDirection"
                      label1="movementDetails.searchResults.fromHolding"
                      label2="movementDetails.searchResults.toHolding"
                      label3="movementDetails.searchResults.permitMove"
                      setModal={setModal}
                    />
                    : <AddressSummary
                      destinationHolding={data.toHolding}
                      info1={data.sourceHolding || data.fromHolding}
                      info2={data.destinationHolding || data.toHolding}
                      info3={data.comment}
                      label="movementDetails.searchResults.movementDirection"
                      label1="movementDetails.searchResults.fromHolding"
                      label2="movementDetails.searchResults.toHolding"
                      label3="movementDetails.searchResults.comments"
                      setModal={setModal}
                    />
                  }

                  {processingFlags && processingFlags.exemptions && processingFlags.exemptions?.length > 0 &&
                    <>
                      <SectionTitleBar
                        icon="bi bi-exclamation-triangle"
                        id="exemptionsSectionTitle"
                        title="boApp.sectionTitles.exemptions"
                      />

                      <ExemptionSummary
                        data={processingFlags}
                        label="movementDetails.searchResults.typeExemptions"
                      />
                    </>
                  }

                  <SectionTitleBar
                    icon="bi bi-list-ol"
                    id="animalDetailsSectionTitle"
                    title="movementDetails.searchResults.animalDetails"
                  />

                  <AnimalSummary
                    batches={batches}
                    batchesRejected={batchesRejected}
                    individualAnimals={individualAnimals}
                    individualAnimalsRejected={individualAnimalsRejected}
                    isCancelledMove={isCancelledMove}
                    isReviewedAndRejected={isReviewedAndRejected}
                    noBottomBorder={true}
                    requestType={requestType}
                    setBatches={setBatches}
                    setBatchesRejected={setBatchesRejected}
                    setIndividualAnimals={setIndividualAnimals}
                    setIndividualAnimalsRejected={setIndividualAnimalsRejected}
                    setModal={setModal}
                    totalAnimals={totalAnimals}
                    totalAnimalsRejected={totalAnimalsRejected}
                    totalBatchAnimals={totalBatchAnimals}
                    totalBatchAnimalsRejected={totalBatchAnimalsRejected}
                    totalIndividualAnimals={totalIndividualAnimals}
                    totalIndividualAnimalsRejected={totalIndividualAnimalsRejected}
                  />

                  <SectionTitleBar
                    icon="bi bi-info-circle"
                    id="fciSectionTitle"
                    title="boApp.sectionTitles.fci"
                  />
                  <FciSummary
                    animalsData={{
                      batches: [],
                      animalsBeingMoved: []
                    }}
                    fciData={fciData}
                    numberBeingMoved={totalBatchAnimals + totalIndividualAnimals}
                  />

                  <SectionTitleBar
                    icon="bi bi-truck"
                    id="transportSummarySectionTitle"
                    title="boApp.sectionTitles.transportDetails"
                  />
                  <TransportSummary
                    label="movementDetails.searchResults.transportInformation"
                    transportInformation={transportInformation}
                  />

                  {(viewErrors?.length > 0 || viewWarnings?.length > 0) &&
                    <>
                      <SectionTitleBar
                        icon="bi bi-exclamation-triangle"
                        id="transportSummarySectionTitle"
                        title="boApp.sectionTitles.errorsAndWarnings"
                      />
                      {viewErrors?.length > 0 && displayErrorOrWarnings(t('boApp.sectionTitles.errors'), viewErrors, viewWarnings?.length === 0)}
                      {viewWarnings?.length > 0 && displayErrorOrWarnings(t('boApp.sectionTitles.warnings'), viewWarnings, false)}
                    </>
                  }

                  {!isEmpty(data) &&
                    <div className="actions">
                      <div className="left">
                        {!confirmMode &&
                          <>
                            {(
                              (
                                crossBorderMove &&
                                !isCancelledMove
                              ) ||
                              (
                                !isPermitMovement &&
                                !crossBorderMove &&
                                !isMovementHandshake &&
                                !moreThan1Record &&
                                !isCancelledMove &&
                                !isReviewedAndRejected &&
                                !isInBusinessMovement &&
                                !isCPHUnknown &&
                                !(isCrossBorderMove && isReportEnglish)
                              )
                            ) &&
                              buttons.confirmButton()
                            }
                            {isMovementHandshake && !crossBorderMove && !isCancelledMove && buttons.undoButton()}

                            {isReviewedAndRejected && buttons.undoReviewButton()}

                            {(
                              (
                                (isPermitMovement || isInBusinessMovement) &&
                                !isCancelledMove
                              ) ||
                              (
                                !crossBorderMove &&
                                !isMovementHandshake &&
                                !moreThan1Record &&
                                !isCancelledMove
                              ) ||
                              (
                                !isPermitMovement &&
                                !crossBorderMove &&
                                !isMovementHandshake &&
                                !moreThan1Record &&
                                !isCancelledMove &&
                                !isReviewedAndRejected &&
                                !isInBusinessMovement &&
                                showAmendButton
                              )
                            ) &&
                              !isXBReportMove &&
                              !isReviewedAndRejected &&
                              buttons.amendButton()
                            }
                          </>
                        }
                      </div>

                      <div className="right">
                        {confirmMode
                          ? <>
                            {buttons.confirmButtonWithChecked()}
                            {buttons.cancelButton()}
                          </>
                          : <>
                            {(isPermitMovement || isInBusinessMovement) && !isCancelledMove && !isXBReportMove && buttons.deleteButton()}
                            {crossBorderMove && !isCancelledMove && buttons.rejectButton()}
                            {!isPermitMovement && !crossBorderMove && !isMovementHandshake && !moreThan1Record && !isCancelledMove && !isReviewedAndRejected && !isInBusinessMovement && !isXBReportMove && buttons.deleteButton()}
                            {buttons.backSearchButton()}
                          </>
                        }
                      </div>
                    </div>
                  }
                </div>
              }
            </>
          }

          {submitted && submitPending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }

          {submitted && !submitPending && pageErrors?.length === 0 &&
            <>
              {helpers.postType.isConfirm(action) && confirmedConfirmation()}
              {helpers.postType.isDelete(action) && deleteConfirmation()}
              {helpers.postType.isReject(action) && rejectedConfirmation()}
              {helpers.postType.isUndo(action) && undoConfirmation()}
            </>
          }
        </>
      }
    </>
  );
};

BoViewMovement.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoViewMovement;
